/**
  * Load FormStack analytics script only if
  * a document has .fsEmbed element.
  */

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.fsEmbed') !== null) {
    /* eslint-disable */
    (function(t,e,n,r,a){var c,i=e[a]=e[a]||{init:function(t){function e(t){
    i[t]=function(){i.q.push([t,[].slice.call(arguments,0)])}}var n,r;i.q=[],
    n='addListener track'.split(' ');for(r in n)e(n[r]);
    i.q.push(['init',[t||{}]])}},s=t.createElement(r);s.async=1,s.src=n,
    c=t.getElementsByTagName(r)[0],c.parentNode.insertBefore(s,c)
    })(document,window,'https://analytics.formstack.com/js/fsa.js','script','FSATracker');

    window.FSATracker.init({
      'account': '683826',
      'endpoint': 'https://analytics.formstack.com',
    });
    /* eslint-enable */
  }
});
