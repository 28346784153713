import $ from 'jquery';


document.addEventListener('DOMContentLoaded', () => {
  // Insert Polls HTML layout into a page dynamically
  const pollBlock = document.getElementById('pollBlock');
  if (pollBlock) {
    const request = new XMLHttpRequest();
    const date = new Date().getTime();
    const url = `/polls/latest/?isHomepage=true&noCacheParam=${date}`;

    request.open('GET', url, true);
    request.onload = () => {
      if (request.status >= 200 && request.status < 400) {
        pollBlock.innerHTML = request.responseText;
      }
    };
    request.send();
  }

  // Poll Form listener fucntion
  $(document).on('submit', '[data-poll]', (e) => {
    const $form = $(e.target);

    if ($form.has('input[name=answer]:checked').length === 0) {
      return false;
    }

    const formData = $form.serializeArray();

    $form.addClass('is-processing');
    $form.find('[data-ajax-error]').hide();

    $.ajax({
      method: 'POST',
      url: '/polls/vote/',
      data: formData,
      cache: false,
      success() {
        $.ajax({
          method: 'GET',
          url: $form.attr('data-poll-success-url'),
          cache: false,
          success(data) {
            $form.addClass('is-success');
            $form.find('[data-ajax-response]').html(data);
            $form.find('[data-more-link]').removeClass('u-hidden');

            $form.removeClass('is-processing');
          },
          error() {
            $form.addClass('is-error');
          },
        });
      },
      error() {
        $form.addClass('is-error');
      },
    });

    return false;
  });
});
