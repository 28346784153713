/* global mediaUtils */
import $ from 'jquery';


// -----------------------------------------------------------------------
// Brightcove init script
// -----------------------------------------------------------------------

document.addEventListener('DOMContentLoaded', () => {
  // Please note that the code that is blocking AbortError Sentry errors relies
  // on this attribute naming scheme.
  [].slice.call(
    document.querySelectorAll('[data-brightcove]'),
  ).forEach((videoEl) => {
    const videoProps = {
      accountID: videoEl.getAttribute('data-account'),
      playerID: videoEl.getAttribute('data-player'),
      videoID: videoEl.getAttribute('data-video-id'),
      adServerUrl: videoEl.getAttribute('data-ad-server-url'),
      endcapImageLink: videoEl.getAttribute('data-endcap-image'),
      endcapUrl: videoEl.getAttribute('data-endcap-url'),
      hasEndcap: videoEl.getAttribute('data-endcap-show') === 'true',
    };
    let player;

    if (window.Sentry) {
      window.Sentry.onLoad(() => {
        window.Sentry.setExtra('videoProps', videoProps);
      });
    }

    function bcPlayerInit() {
      player = window.videojs.getPlayer(`video${videoProps.videoID}`);

      // Sometimes, even though video.js has initialized and the HTML element
      // is present, the player won't work. Most of the Sentry errors come from
      // Facebook's IPs, so most likely another fault in their cut-down
      // environment
      if (!player) {
        return;
      }

      if (typeof player.ima3 === 'function') {
        player.ima3({
          adTechOrder: [
            'html5',
          ],
          requestMode: 'onload',
          serverUrl: videoProps.adServerUrl,
          timeout: 4000,
          vpaidMode: 'ENABLED',
        });
      }

      player.ready(function () {
        const myPlayer = this;

        const showEndcap = function () {
          const playerContainer = $(this.el());
          const endcapContainer = document.createElement('div');

          const endcapImage = document.createElement('img');
          endcapImage.className = 'Video-thumb';
          endcapImage.src = videoProps.endcapImageLink;

          const endcapLink = document.createElement('a');
          endcapLink.href = videoProps.endcapUrl;
          endcapLink.appendChild(endcapImage);

          endcapContainer.appendChild(endcapLink);

          playerContainer.replaceWith(endcapContainer);
        };

        if (videoProps.hasEndcap) {
          myPlayer.one('ended', showEndcap);
        }
      });
    }

    function bcScriptLoaded(e, error) {
      if (error) {
        if (window.console && window.console.warn) {
          window.console.warn(error.message);
        }
        return;
      }

      if (videoProps.adServerUrl) {
        const path = 'https://players.brightcove.net/videojs-ima3/3/';
        mediaUtils.injectLink(`${path}videojs.ima3.min.css`);
        mediaUtils.injectScript(
          `${path}videojs.ima3.min.js`, bcPlayerInit,
        );
      } else {
        bcPlayerInit(e);
      }
    }

    mediaUtils.injectScript(
      `https://players.brightcove.net/${videoProps.accountID}/` +
        `${videoProps.playerID}_default/index.min.js`,
      bcScriptLoaded,
    );
  });
});
