/* global dataLayer, mediaUtils */

mediaUtils.domReady(() => {
  function sendHeadlineDataLayer(headline, type) {
    dataLayer.push({
      event: 'Breaking News Top Headline',
      eventCategory: headline.getAttribute('data-headline-type'),
      eventAction: `${headline.getAttribute('data-headline-text')} : ${type}`,
    });
  }

  function hideHeadline(headline) {
    if (mediaUtils.isCookieAllowed) {
      mediaUtils.setCookie(
        'hideTopHeadline', headline.getAttribute('data-headline-id'),
      );
    }

    // Not removing the items because we might still want to use its data
    headline.classList.add('u-hidden');
    sendHeadlineDataLayer(headline, 'close');
    return false;
  }

  document.addEventListener('click', (e) => {
    if (e.target.nodeType !== Node.ELEMENT_NODE) { return; }
    const type = e.target.getAttribute('data-headline-btn');

    if (!type) { return; }

    const parent = e.target.closest('[data-headline]');

    if (type === 'close') {
      hideHeadline(parent);
      e.preventDefault();
    } else if (type === 'click') {
      sendHeadlineDataLayer(parent, 'click');
    }
  });

  [].slice.call(
    document.querySelectorAll('[data-headline]'),
  ).forEach((headline) => {
    if (headline.hasAttribute('data-headline-id')) {
      sendHeadlineDataLayer(headline, 'view');
    }
  });
});
