/* global $ */

/**
 * The "How To Buy Guides" functionality.
 * TODO: might want to make this more universal, not tied to names/IDs, etc.
 */

/**
 * @brief Checks if any of the checkboxes are selected and activates or
 * deactivates the button(s) accordingly
 */
function htbBtnActivate() {
  const btns = document.querySelectorAll('[data-htb-submit-btn]');

  // Hide the button(s) if there are no items to select
  if (document.querySelectorAll('[data-htb-guide-checkbox]').length) {
    [].slice.call(btns).forEach((el) => {
      el.classList.remove('u-hidden');
    });
  } else {
    [].slice.call(btns).forEach((el) => {
      el.classList.add('u-hidden');
    });

    return;
  }

  // The button(s) should only be enabled if there are selected items
  if (document.querySelectorAll('[data-htb-guide-checkbox]:checked').length) {
    [].slice.call(btns).forEach((el) => {
      el.removeAttribute('disabled');
    });
  } else {
    [].slice.call(btns).forEach((el) => {
      el.setAttribute('disabled', true);
    });
  }
}

window.guideOnChange = (el, value, onChange) => {
  document.addEventListener('change', (e) => {
    const control = e.target;

    // Guide checkboxes
    if (control.hasAttribute('data-htb-guide-checkbox')) {
      const values = [].slice.call(
        document.querySelectorAll('[data-htb-guide-checkbox]:checked'),
      ).map((checkbox) => checkbox.value);

      onChange(values.join(';'));
    }
  });
};

window.guideUrlsOnChange = (el, value, onChange) => {
  document.addEventListener('change', (e) => {
    const control = e.target;

    // Guide checkboxes
    if (control.hasAttribute('data-htb-guide-checkbox') &&
      control.hasAttribute('data-htb-guide-url')) {
      const values = [].slice.call(
        document.querySelectorAll('[data-htb-guide-checkbox]:checked'),
      ).map((checkbox) => checkbox.getAttribute('data-htb-guide-url'));

      onChange(values.join(';'));
    }
  });
};

/**
 * Will fire after the lead form has submitted successfully
 */
window.submitGuidesOnSuccess = (formData, formElement, response) => {
  // TODO: pass this as an arg to the function?
  const form = formElement.closest('[data-cub-form-container]');
  $.post(
    form.getAttribute('data-action'),
    {
      posts: formData.posts.trim().split(';'),
      lead: response.id,
    },
    () => {
      window.location = form.getAttribute('data-redirect');
    },
  );
};

/**
 * Doing stuff when the guide checkboxes are (un)checked
 */
document.addEventListener('change', (e) => {
  const control = e.target;

  // Guide checkboxes
  if (control.hasAttribute('data-htb-guide-checkbox')) {
    htbBtnActivate();
  }

  // The "my guides only" filter
  if (control.getAttribute('data-htb-filter') === 'my') {
    if (control.checked) {
      window.location.search = `filter=${control.value}`;
    } else {
      window.location.search = '';
    }
  }
});

/**
 * Doing stuff on clicks
 */
document.addEventListener('click', (e) => {
  // The "Get the guides" button
  if (e.target.nodeType !== Node.ELEMENT_NODE) { return; }
  const btn = e.target.closest('[data-htb-submit-btn]');
  if (!btn) { return; }
  if (document.querySelectorAll('[data-htb-guide-checkbox]:checked').length) {
    $('#modal-guides-form').modal('show');
  }
});

window.htbInit = () => {
  htbBtnActivate();
};

window.addEventListener('DOMContentLoaded', window.htbInit);
