/* global ga, dataLayer */
import $ from 'jquery';
import smartdate from 'smartdate';


$(document).ready(() => {
  const $relateContentFilters = $('#related-content-filters');
  const $relateContentFiltersMobile = $('#related-content-filters-mobile');
  let lioAddCallback;
  // Secured Content related variabled
  const $securedContentBlock = $('[data-analytics-secured-content]');
  let securedContentBlockType;
  const $securedContentLinks = $('[data-analytics-secured-link]');

  function loadArticles(url, title, pageTitle) {
    const relatedContentContainerEmpty = $('#related-content-container-empty');
    const relatedContentContainer = $('#related-content-container');

    relatedContentContainer.load(url,
      (responseText, status) => {
        if (status === 'success') {
          relatedContentContainer.show();
          relatedContentContainerEmpty.hide();

          smartdate.render();
        } else {
          relatedContentContainer.hide();


          relatedContentContainerEmpty
            .html(`We're sorry, there are no ${title} ${pageTitle} available`)
            .show();
        }
      });
  }

  function updateTopicRelatedContent(url, title, pageTitle) {
    loadArticles(url, title, pageTitle);

    if (history) {
      history.pushState({
        url,
        title,
        pageTitle,
      }, pageTitle, url);
    }

    if (window.ga) {
      ga('send', {
        hitType: 'event',
        eventCategory: 'Topic Page Sort',
        eventAction: 'Click',
        eventLabel: title,
      });
    }
  }

  window.onpopstate = (event) => {
    const state = event && event.state;
    if (state) {
      $relateContentFilters.find('li').removeClass('is-active');
      $relateContentFilters.find(`a[data-href="${state.url}"]`)
        .closest('li').addClass('is-active');

      loadArticles(state.url, state.title, state.pageTitle);
    }
  };

  // TODO: we have a similar functionality in ajax-loading.js. Unite?
  $relateContentFilters.on('click', 'a[data-href]', function (event) {
    const $this = $(this);
    const title = $this.text();
    const pageTitle = $relateContentFilters.data('page-title');
    const url = $this.data('href');

    $relateContentFilters.find('li').removeClass('is-active');
    $this.closest('li').addClass('is-active');
    // change mobile filter selected value
    $relateContentFiltersMobile.val(url);

    event.preventDefault();

    updateTopicRelatedContent(url, title, pageTitle);
  });

  $relateContentFiltersMobile.on('change', function () {
    const $this = $(this);
    const title = $this.text();
    const pageTitle = $relateContentFiltersMobile.data('page-title');
    const url = $this.val();

    // change desktop filter selected value
    $relateContentFilters.find('li').removeClass('is-active');
    $relateContentFilters.find(`a[data-href="${url}"]`).closest('li')
      .addClass('is-active');

    updateTopicRelatedContent(url, title, pageTitle);
  });

  function getRecommendedArticle(userData, exludedArticleId, callback) {
    $.ajax({
      url: '/articles/RecommendedArticle',
      type: 'POST',
      data: JSON.stringify({
        slugs: userData.segments,
        excludedArticleId: exludedArticleId,
      }),
      contentType: 'application/json; charset=utf-8',
      success: callback,
    });
  }

  function sendNativeContentWidgetDataLayer(slug, sponsorName, actionType) {
    if (window.dataLayer) {
      dataLayer.push({
        event: `Native Content Widget: ${sponsorName}`,
        eventCategory: 'Lytics',
        eventAction: `${slug} : ${actionType}`,
      });
    }
  }

  function fillRecommendationTemplate(article, $template) {
    const httpTest = /^http/i;
    let articleUrl = article.url;
    const trackingTemplate = $template.data('tracking-link-template');
    let $imageBlock;
    const $articleLinks = $template.find('[data-rec-type="url"]');

    if (!httpTest.test(article.url)) {
      articleUrl = `//${article.url}`;
    }

    $template.find('a[data-rec-type="url"]').attr('href', articleUrl);
    $template.find('*[data-rec-type="title"]').text(article.title);
    $template.find('*[data-rec-type="description"]')
      .text(article.description || '');

    if (trackingTemplate) {
      $template.find('a[data-rec-type="url"]').attr(
        'data-tracking-link',
        trackingTemplate.replace('{content_id}', article.id)
          .replace('{campaign_id}', article.campaign_id),
      );
    }

    if (article.image) {
      $imageBlock = $template
        .find('*[data-rec-type="image"]')
        .removeClass('u-hidden');

      $imageBlock
        .find('img')
        .attr('src', article.image)
        .attr('alt', article.title);
    }

    $template.removeClass('u-hidden');

    sendNativeContentWidgetDataLayer(article.slug, article.sponsor, 'view');

    $articleLinks.each(function () {
      let linkType;
      if ($(this).hasClass('TitleBar')) {
        linkType = 'Header';
      } else if ($(this).hasClass('HCard-imgTitleWrap')) {
        linkType = 'Headline';
      } else if ($(this).hasClass('Link')) {
        linkType = 'Read More';
      }

      $(this).on('click', () => sendNativeContentWidgetDataLayer(
        article.slug,
        article.sponsor,
        `click : ${linkType}`,
      ));
    });
  }

  if (window.liosetup) {
    if (typeof window.liosetup.addCallback === 'function') {
      lioAddCallback = window.liosetup.addCallback;
    } else if (
      typeof window.liosetup.addEntityLoadedCallback === 'function') {
      lioAddCallback = window.liosetup.addEntityLoadedCallback;
    }
  }

  if (typeof (lioAddCallback) === 'function') {
    lioAddCallback((data) => {
      // check if data exists (Lytics loaded)
      if (!data) return;

      const $template = $('div[data-rec-block="sponsor-recommendations"]');
      if (!$template.length) {
        return;
      }

      const currentArticleId = $template.data('rec-current-article');
      getRecommendedArticle(data,
        currentArticleId,
        (article) => {
          if (article) {
            fillRecommendationTemplate(article, $template);
          }
        });
    });
  }

  // Capitalize first letter in string
  function capitalize(str) {
    return str[0].toUpperCase() + str.slice(1);
  }

  // Check the Secured Content Block and send data to GA
  // (see PreviewPage.cshtml, VideoPartial.cshtml)
  if ($securedContentBlock.length) {
    securedContentBlockType = capitalize(
      $securedContentBlock.attr('data-analytics-secured-content'),
    );

    if (window.dataLayer) {
      dataLayer.push({
        event: 'gaEventNonInt',
        eventCategory: `Secured ${securedContentBlockType} Block`,
        eventAction: 'view',
        eventLabel: `${securedContentBlockType}`,
      });
    }

    $securedContentLinks.on('click', (e) => {
      if (window.ga) {
        const linkType = $(e.target).attr('data-analytics-secured-link');
        const linkPurpose = $(e.target)
          .attr('data-analytics-secured-link-purpose');
        dataLayer.push({
          event: 'gaEventNonInt',
          eventCategory: `Secured ${securedContentBlockType} Block`,
          eventAction: `${linkType} : click : ${linkPurpose}`,
          eventLabel: `${securedContentBlockType}`,
        });
      }
    });
  }
});
