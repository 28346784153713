/* global Sentry */

// Libs that we need globally (exposed via webpack's config)
// eslint-disable-next-line import/no-extraneous-dependencies
import 'expose-loader?exposes=$,jQuery!jquery';
import 'jquery-migrate';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'expose-loader?exposes=_!underscore';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'expose-loader?exposes=smartdate!smartdate';

// Commenting out until this gets implemented
// https://github.com/videojs/video.js/issues/6781
// import 'tapjs';
import 'jquery-hoverintent';
import 'superfish';
import 'owl.carousel';
import 'helpers/owl.carousel.linked.js';

import 'helpers/jquery.serializeForm.js';
import 'jquery-validation';
import 'bootstrap-sass/assets/javascripts/bootstrap/transition.js';
import 'bootstrap-sass/assets/javascripts/bootstrap/modal.js';
import 'ajax/ajax-tools.js';
import 'waypoints/lib/noframework.waypoints.js';
// init scripts
import 'cub-lead-forms.js';
import 'cub-forms-init.js';
import 'tracking-init.js';
import 'menu-init.js';
import 'smartdate-init.js';
import 'init.js';
import 'debug.js';
import 'modal-init.js';
import 'brightcove-init.js';
import 'owl.carousel-init.js';
import 'forms-init.js';
import 'polls.js';
import 'tables.js';
import 'ajax/endless-loader.js';
import 'ajax/ajax-loading.js';
import 'articles-init.js';
import 'headline-init.js';
import 'formstack-init.js';
import 'htb.js';

Sentry.onLoad(() => {
  Sentry.setExtra('head.js has run', true);
});
